import * as yup from "yup";
import Link from "next/link";
import Image from "next/image";
import Toast from "@components/toast";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";
import { useSession } from "next-auth/react";
import { useSettings } from "@hooks/settings";
import { useTranslation } from "next-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["auth"])),
    },
  };
}

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: session, status } = useSession();
  const { t } = useTranslation("auth");
  const router = useRouter();
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);
  const settings = useSettings();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await signIn("credentials", {
      redirect: false,
      email: data.email,
      password: data.password,
      callbackUrl: router.query.callbackUrl
        ? router.query.callbackUrl
        : window.location.origin,
    });

    if (res.error) {
      setLoading(false);
      return notify("error", res.error);
    }

    setLoading(false);
  };

  if (status != "loading" && session) {
    // if callbackUrl is set, redirect to it
    if (router.query.callbackUrl) {
      router.push(router.query.callbackUrl);
      return null;
    }
    notify("info", t("auth:alreadySignedIn"));
    router.push(
      `${router.locale === router.defaultLocale ? "" : `/${router.locale}`}/${
        session?.user?.language
      }/companies`
    );
    return null;
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="d-none d-md-block col-md-8 col-lg-6 col-xl-5">
            <Image
              src="/images/login-animate.svg"
              alt="Login"
              width={400}
              height={400}
              priority
              className="d-block mx-auto mb-4"
              style={{ width: "auto", height: "auto" }}
            />
          </div>
          <div className="col-12 col-md-8 col-lg-6 col-xl-4">
            <div className="card" style={{ borderRadius: "6px" }}>
              <div className="card-header">
                <Image
                  src={settings.app_logo}
                  alt={settings.app_name}
                  width={100}
                  height={100}
                  priority
                  className="d-block mx-auto mb-4"
                  style={{ width: "auto", height: "auto" }}
                />
                <h1 className="display-6 text-center mb-3">
                  {t("auth:signIn.title")}
                </h1>
              </div>

              <div className="card-body">
                <div className="m-sm-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* email */}
                    <div className="mb-3">
                      <label className="form-label">
                        {t("auth:fields.email.label")}
                      </label>
                      <input
                        className="form-control form-control-lg"
                        type="email"
                        {...register("email")}
                      />
                      {errors.email && (
                        <div className="text-danger">
                          {errors.email.message}
                        </div>
                      )}
                    </div>

                    {/* password */}
                    <div className="mb-3">
                      <label className="form-label">
                        {t("auth:fields.password.label")}
                      </label>
                      <div className="input-group flex-nowrap">
                        <input
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          {...register("password")}
                        />
                        <span className="input-group-text" id="addon-wrapping">
                          <i
                            className={`far 
                            ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                            onClick={togglePasswordVisibility}
                          />
                        </span>
                      </div>

                      {errors.password && (
                        <div className="text-danger">
                          {errors.password.message}
                        </div>
                      )}
                    </div>

                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn btn-lg btn-bota-light-blue"
                        disabled={loading}
                      >
                        {t("auth:signIn.title")}
                      </button>
                    </div>
                  </form>

                  {/* <div className="row mt-3">
                    <div className="col-12 col-md-6">
                      <Image
                        src="/images/btn_google_signin_dark_normal_web@2x.png"
                        alt="Google Logo"
                        width={200}
                        height={100}
                        priority
                        className="d-block mx-auto mb-4 btn btn-lg p-0"
                        style={{ width: "auto", height: "auto" }}
                        // onClick={() =>
                        //   signIn("google", {
                        //     callbackUrl: router.query.callbackUrl
                        //       ? router.query.callbackUrl
                        //       : window.location.origin,
                        //   })
                        // }
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <Image
                        src="/images/facebook_login.png"
                        alt="Facebookgo"
                        width={200}
                        height={100}
                        priority
                        className="d-block mx-auto mb-4 btn btn-lg p-0"
                        style={{ width: "auto", height: "auto" }}
                        // onClick={() =>
                        //   signIn("facebook", {
                        //     callbackUrl: router.query.callbackUrl
                        //       ? router.query.callbackUrl
                        //       : window.location.origin,
                        //   })
                        // }
                      />
                    </div>

                    <div className="col-12">
                      <Image
                        src="/images/twitter_button.png"
                        alt="Twitter Logo"
                        width={200}
                        height={100}
                        priority
                        className="d-block mx-auto mb-4 btn btn-lg p-0"
                        style={{ width: "auto", height: "auto" }}
                        // onClick={() =>
                        //   signIn("twitter", {
                        //     callbackUrl: router.query.callbackUrl
                        //       ? router.query.callbackUrl
                        //       : window.location.origin,
                        //   })
                        // }
                      />
                    </div>
                  </div> */}

                  <div className="row mt-4">
                    <div className="col-6">
                      <p className="text-start">
                        <small className="text-muted text-center">
                          {t("auth:forgotPassword")}{" "}
                          <Link
                            href={
                              router.query.callbackUrl
                                ? `/auth/password/reset?callbackUrl=${encodeURIComponent(
                                    router.query.callbackUrl
                                  )}`
                                : "/auth/password/reset"
                            }
                            className="text-bota-light-blue"
                          >
                            {t("auth:resetPassword")}
                          </Link>
                        </small>
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="text-end">
                        <small className="text-muted text-center">
                          {t("auth:dontHaveAccount")}{" "}
                          <Link
                            href={
                              router.query.callbackUrl
                                ? `/auth/signup?callbackUrl=${encodeURIComponent(
                                    router.query.callbackUrl
                                  )}`
                                : "/auth/signup"
                            }
                            className="text-bota-light-blue"
                          >
                            {t("auth:signUp.title")}
                          </Link>
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
